import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-info-reserve',
  templateUrl: './info-reserve.component.html',
  styleUrls: ['./info-reserve.component.scss'],
})
export class InfoReserveComponent implements OnInit {

  constructor(
    public popoverController: PopoverController
  ) { }

  ngOnInit() {}

  infoReserveDismiss() {
    this.popoverController.dismiss();
  }
}
