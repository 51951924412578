import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpRequest,
  HttpHandler, HttpEvent, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    public toastController: ToastController
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(
          
          (error: HttpErrorResponse) => {

            var msg = "";

            if (error.error instanceof ErrorEvent) {
              // A client-side or network error occurred. Handle it accordingly.
              msg ='An error occurred:' + error.error.message;
            } else if (error.error.Message) {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              msg = error.error.Message;
            } else if (error.status == 0) {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              msg = "Internet connection not available.";
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              msg = error.error;
            }
        
            if (this.toastController) {
              this.presentToast(msg);
            }
        
            return throwError(error);
          }
        )
      )
  };

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 4000
    });
    toast.present();
  }

}