import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@capacitor/core';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { Globals } from '../common/globals';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) { }

  login(email: string, password: string) {
    var body = { 
      email: email, 
      password: password
    };
    return this.http.post<User>(environment.apiUrl + '/login', JSON.stringify(body), environment.JSON_HEADERS);
  }

  async logout() {
    //persist user on local device

    //clear user on local device
    await Storage.remove({ key: 'currentUser' });

    //clear instance reference
    Globals.currentUser = null;
  }

  async getCurrentUser(): Promise<User> {
    var user: User = null;

    if (Globals.currentUser) {
      user = Globals.currentUser;
    } else {
      const ret = await Storage.get({ key: 'currentUser' });
      if (ret && ret.value) {
        user = JSON.parse(ret.value);
      }
    }

    //Trim CC number to last 4
    if (user.cardNumber && user.cardNumber.length > 4) {
      user.cardNumber = user.cardNumber.substr(user.cardNumber.length - 4, 4);
    }

    return user;
  }
  // load(locationId) {
  //   return this.http.get<Contract[]>(environment.apiUrl + '/contracts?locationId=' + locationId, { headers: { 'Content-Type': 'application/json', 'Authorization': this.authService.key, 'Username': this.authService.currentUsername() } });
  // }

  // loadContract(contractId) {
  //   return this.http.get<Contract>(environment.apiUrl + '/contracts/' + contractId, { headers: { 'Content-Type': 'application/json', 'Authorization': this.authService.key, 'Username': this.authService.currentUsername() } });
  // }

  async createUser(user: User) : Promise<User> {
    let returnUser = await this.http.post<User>(environment.apiUrl + '/users', JSON.stringify(user), { headers: { 'Content-Type': 'application/json' } }).toPromise();

    //user.id = returnUser.id;

    //update qr code
    this.setQrcode(returnUser);

    //persist user on local device
    Storage.set({
      key: 'currentUser', 
      value: JSON.stringify(returnUser)
    });

    //save instant reference
    Globals.currentUser = returnUser;

    return returnUser;
  }

  async updateUser(user: User) : Promise<User> {
    let returnUser = await this.http.put<User>(environment.apiUrl + '/users', JSON.stringify(user), { headers: { 'Content-Type': 'application/json' } }).toPromise();

    //user.id = returnUser.id;

    //update qr code
    this.setQrcode(returnUser);

    //persist user on local device
    Storage.set({
      key: 'currentUser', 
      value: JSON.stringify(returnUser)
    });

    //save instant reference
    Globals.currentUser = returnUser;
    console.log('UPDATED USER!!!!!!!!!!!!!!!!!!!!!!');
    console.log(returnUser);
    return returnUser;
  }

  // update(contract) {
  //   return this.http.put<Contract>(environment.apiUrl + `/contracts/${contract.id}`, JSON.stringify(contract), { headers: { 'Content-Type': 'application/json', 'Authorization': this.authService.key, 'Username': this.authService.currentUsername() } });
  // }

  // delete(contract) {
  //   return this.http.delete(environment.apiUrl + `/contracts/${contract.id}`, { headers: { 'Content-Type': 'application/json', 'Authorization': this.authService.key, 'Username': this.authService.currentUsername() } });
  // }

  setQrcode(user: User) {
    var s = user.userId;
    if (user.members) {
      for(var i = 0; i < user.members.length; i++) {
        var member = user.members[i];
        s += ',' + member.locationName + ':' + member.localMemberNumber
      }
    }
    if (user.coupons) {
      for(var i = 0; i < user.coupons.length; i++) {
        var coupon = user.coupons[i];
        s += ',CPN:' + coupon.code;

        //only include the first/top coupon
        break;
      }
    }
    user.qrcode = s;
  }
}
