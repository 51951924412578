import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { Lookup } from '../models/lookup';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(
    private http: HttpClient,
  ) { }

  getLookup(): Observable<Lookup> {
    return this.http.get<Lookup>(environment.apiUrl + '/lookups', { headers: { 'Content-Type': 'application/json' } })
  }
}
