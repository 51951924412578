import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  // {
  //   path: 'member/:locationId/:localMemberNumber',
  //   loadChildren: () => import('./pages/member/member.module').then( m => m.MemberPageModule)
  // },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'fastpass',
    loadChildren: () => import('./pages/fastpass/fastpass.module').then( m => m.FastpassPageModule)
  },
  {
    path: 'reserve',
    loadChildren: () => import('./pages/reserve/reserve.module').then( m => m.ReservePageModule)
  },
  {
    path: 'shuttle',
    loadChildren: () => import('./pages/shuttle/shuttle.module').then( m => m.ShuttlePageModule)
  },
  {
    path: 'fastpass-edit/:locationId/:userId',
    loadChildren: () => import('./pages/fastpass-edit/fastpass-edit.module').then( m => m.FastpassEditPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
