import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LookupService } from './services/lookup.service';
import { UserService } from './services/user.service';
import { Globals } from './common/globals';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [];
  public appPagesDefault = [
    {
      title: 'Home (View QR Code)',
      url: '/welcome',
      icon: 'home',
      condition: ''
    },
    {
      title: 'Login',
      url: '/login',
      icon: 'log-in',
      condition: 'unauthenticated'
    },
    {
      title: 'Register',
      url: '/register',
      icon: 'clipboard',
      condition: 'unauthenticated'
    },
    {
      title: 'Manage FastPass Card/Mobile App',
      url: '/fastpass',
      icon: 'card',
      condition: 'authenticated'
    },
    {
      title: 'Contact',
      url: '/contact',
      icon: 'call',
      condition: 'authenticated'
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'log-out',
      condition: 'authenticated'
    },
  ];
  //public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private lookupService: LookupService,
    private userService: UserService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    Globals.appComponent = this;

    //refresh lookups
    this.lookupService.getLookup()
      .subscribe((lookup) => {
        Globals.lookup = lookup;
      });
    
    //check for currentUser in local storage
    this.userService.getCurrentUser()
      .then((u) => {
        Globals.currentUser = u;
        this.initMenu();
      });

    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }
  }

  public initMenu() {
    this.appPages = [];
    this.appPagesDefault.forEach((ap) => {
      if (ap.condition == "unauthenticated" && Globals.currentUser) {
        return;
      } else if (ap.condition == "authenticated" && !Globals.currentUser) {
        return;
      } else {
        this.appPages.push(ap);
      }
    });
  }
}
